export const TWEEN = require( '@tweenjs/tween.js' );

export function animate () {
  if ( TWEEN.update() ) {
    requestAnimationFrame( animate );
  }
}

export function startAnimation ( target, destination, option, onStart=null ) {
  const { duration, delay, easing } = option;
  const tw = new TWEEN.Tween( target )
    .to( destination, duration )
    .easing( easing )
    .delay( delay )
    .onStart( onStart )
    .start()
  ;
  animate();
  return tw;
}
