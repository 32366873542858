import { reactive, computed, } from 'vue';
import { TWEEN, startAnimation, } from 'src/js/lib/anim.js';

export const useAnim = function ( origin ) {
  const init = Object.assign( {}, origin );
  const anim = reactive( init ) ;

  const start = function ( destination, option, onStart=null ) {
    return startAnimation( anim, destination, option, onStart );
  };

  const back = function ( option, onStart, ) {
    return startAnimation( anim, origin, option, onStart );
  };

  const flush = function () {
    Object.keys( origin ).forEach( ( key ) => {
      anim[ key ] = origin[ key ];
    });
  };

  const set = function ( obj ) {
    Object.assign( anim, obj );
  };

  return { anim, start, back, set, flush, };
};

export const useStyleOpacity = ( anim ) => {
  return computed( () => {
    return { opacity: anim.o };
  });
};

export const useStyleTransform = ( anim, ) => {
  return computed( () => {
    const { x, y, s, } = anim;
    return {
      transform: `translate3d( ${ x }px, ${ y }px, 0 ) scale( ${ s }, ${ s } )`,
    };
  });
};

export const option_nav = {
  easing: TWEEN.Easing.Quadratic.InOut,
  duration: 450,
  delay: 0,
};

export const option_img = {
  easing: TWEEN.Easing.Quintic.InOut,
  duration: 600,
  delay: 0,
};
