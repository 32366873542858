import { getRect, } from 'src/js/lib/box.js';
import { reactive, computed, } from 'vue';
import { useResizeListener, } from 'src/js/composable/event.js';
import { viewport, } from 'src/js/composable/viewport.js';

const id = 'the-app';

export const el = document.getElementById( id );

export const rect = reactive( getRect( el ) );

export const fill = computed( () => {
  const top = rect.top;
  const width = rect.width;
  const height = ( viewport.height - rect.top );
  return { top, width, height, };
});

export const styleFill = computed( () => {
  const { top, height, } = fill.value;
  const { width, } = viewport;
  return {
    position: 'fixed',
    left: 0,
    top: `${ top }px`,
    width: `${ width }px`,
    height: `${ height }px`,
    minHeight: '100%',
  };
});

const update = function () {
  Object.assign( rect, getRect( el ) );
};

useResizeListener( update );
