const FastClick = require( 'fastclick' );

/* Module */
import { createApp, } from 'vue/dist/vue.esm-bundler.js';

/* Compose */
import { el, } from 'src/js/composable/app.js';

/* Component */
import MjSvgOrnament from 'src/vue/component/svg-ornament.vue';

FastClick.attach( document.body );

const app = createApp( {
  components: {
    MjSvgOrnament,
  },
});

app.mount( el );
