import { getViewportSize, } from 'src/js/lib/box.js';
import { useResizeListener, } from 'src/js/composable/event.js';

import { reactive, } from 'vue';

export const viewport = reactive( getViewportSize() );

export const update = function () {
  Object.assign( viewport, getViewportSize() );
};

useResizeListener( update );
