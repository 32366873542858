import { getViewportSize } from 'src/js/lib/box.js';

/**
 * @function
 * @name getScroll
 * @return { Object }
 *  @prop { Number } x
 *  @prop { Number } y
 * @desc window のスクロール値を取得
 */
export const getScroll = function () {
  return {
    x: window.pageXOffset || document.documentElement.scrollLeft,
    y: window.pageYOffset || document.documentElement.scrollTop,
  };
};

/**
 * @function
 * @name getElementScroll
 * @param { Element } elm
 * @return { Object }
 *  @prop { Number } x
 *  @prop { Number } y
 * @desc 要素内のスクロール値を取得
 */
export const getElementScroll = function ( elm ) {
  return {
    x: elm.scrollLeft,
    y: elm.scrollTop,
  };
};

/**
 * @function
 * @name hasScrolled
 * @return { Boolean }
 * @desc window 高さを越えたところまでスクロールが進行したか
 */
export function hasScrolled () {
  const { height } = getViewportSize();
  const { y } = getScroll();
  return ( y > height );
}

/**
 * @function
 * @name toTop
 * @desc 強制で window 頭に戻す
 */
export function toTop () {
  window.scrollTo( 0, 0 );
}
