import { ref, } from 'vue';

export const useState = ( val ) => {
  const state = ref( val );
  const toggle = () => state.value = ! state.value;
  const on = () => state.value = true;
  const off = () => state.value = false;
  const set = ( n ) => state.value = n;
  const get = () => state.value;
  return { state, toggle, on, off, set, get, };
};
