<script setup>
/* Module */
import { SVG, } from '@svgdotjs/svg.js';
import Hammer from 'hammerjs';

/* Lib */
import { TWEEN, } from 'src/js/lib/anim.js';
import { getPlusOrMinusSign, } from 'src/js/lib/number.js';

/* Compose */
import { ref, computed, onMounted, watch, } from 'vue';
import { useFetch, } from 'src/js/composable/fetch.js';
import { useAnim, option_img, }from 'src/js/composable/anim.js';
import { useState, } from 'src/js/composable/state.js';

const props = defineProps( Object.assign( {}, {
  src: {
    type: String,
    required: true,
  },
  flip: {
    type: [ Boolean, String, ],
    default: () => false,
  },
  rotate: {
    type: [ Boolean, Number, ],
    default: () => false,
  },
}));

const getRotate = () => {
  const coefficient = getPlusOrMinusSign();
  const r = Math.random() * 360;
  return coefficient * r;
};
const el = ref();
let hammer = null;
const { anim: anim_o, start: start_o, } = useAnim( { o: 0, } );
const { anim: anim_r, start: start_r, back: back_r, } = useAnim( { r: getRotate(), } );
const { data, } = useFetch( props.src, 'text' );
const { duration, } = option_img;
const option_bounce = {
  duration,
  delay: duration / 1.5,
  easing: TWEEN.Easing.Bounce.Out,
};
const is_touch_device = useState( false );

const styleOpacity = computed( () => {
  const { o } = anim_o;
  return { opacity: o, };
});

const styleRotate = computed( () => {
  const { r } = anim_r;
  return {
    transform: `translate3d( 0, 0, 0 ) scale( 1, 1 ) rotateZ( ${ r }deg )`,
  };
});

const styleCusor = computed( () => {
  const cus = ( data.value ) ? 'grab' : 'auto';
  return { cursor: cus, };
});

const draw = () => {
  const svg = SVG( data.value ).size( '100%', '100%' ).css( { overflow: 'visible', } );
  const g = svg.group();

  svg.children().forEach( ( elm ) => {
    elm.toParent( g );
  });

  if ( props.flip ) g.flip( props.flip );
  if ( props.rotate ) g.rotate( props.rotate );

  svg.addTo( el.value );

  start_o( { o: 1, }, option_img );

  g.scale( 0.25 )
    .animate( duration * 2 )
    .ease( TWEEN.Easing.Elastic.InOut )
    .scale( 4 )

  start_r( { r: 0, }, option_bounce );
};

const onMouseEnter = () => {
  if ( is_touch_device.state.value ) return;
  const { duration, easing } = option_bounce;
  back_r( { duration, delay:0, easing, } );
};

const onMouseLeave = () => {
  if ( is_touch_device.state.value ) return;
  const { duration, easing } = option_bounce;
  start_r( { r: 0, }, { duration, delay: 0, easing, } );
};

const onTap = ( { pointerType, } ) => {
  if ( 'mouse' === pointerType ) {
    is_touch_device.off();
    return;
  } else {
    is_touch_device.on();
    hammer.off( 'tap', onTap );
  }
  const { duration, easing } = option_bounce;
  const coefficient = getPlusOrMinusSign();
  const cur = anim_r.r;
  const to = cur + coefficient * 360;
  start_r( { r: to, }, { duration, delay: 0, easing, } ).onComplete( () => {
    hammer.on( 'tap', onTap );
  });
};

onMounted( () => {
  watch( data, () => {
    if ( data.value ) {
      draw();
      hammer = new Hammer.Manager( el.value, {
        recognizers: [
          [ Hammer.Tap, ],
        ],
      });
      hammer.on( 'tap', onTap );
    }
  });
});
</script>

<template>
  <i
    ref="el"
    :style="[ styleOpacity, styleRotate, styleCusor, ]"
    class="d-ib b-fill"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  />
</template>
