// Mod
import { isNil, } from 'lodash';

// Lib
import {
  getScroll,
  getElementScroll,
} from 'src/js/lib/scroll.js';

/**
 * @return { Object }
 * @property { number } width
 * @property { number } height
 */
export function getBox ( el ) {
  let { width, height } = el.getBoundingClientRect();
  if ( ( width <= 0 ) || ( height <= 0 ) ) {
    const computed = window.getComputedStyle( el );
    width = computed.getPropertyValue( 'width' );
    height = computed.getPropertyValue( 'height' );
    [ width, height, ] = [ width, height ].map( s => parseInt( s, 10 ) );
  }
  return { width, height, };
}

/**
 * @param { boolean } [ isOuter = false ]
 * @return { Object }
 *  @prop { number } width
 *  @prop { number } height
 */
export function getViewportSize ( isOuter = false ) {
  return {
    width: ( isOuter ) ? window.outerWidth : window.innerWidth,
    height: ( isOuter ) ? window.outerHeight : window.innerHeight,
  }
}

/**
 * @param { Element } elm
 * @param { Element } [ context ]
 * @param { Boolean } [ relative = true] Relative to the the document.
 * @return { Object }
 *  @property { number } top
 *  @property { number } left
 *  @property { number } right
 *  @property { number } bottom
 *  @property { number } width
 *  @property { number } height
 */
export function getRect ( elm, context, relative = true ) {
  let scroll_x = 0;
  let scroll_y = 0;
  let c_scroll_x = 0;
  let c_scroll_y = 0;
  let c_top = 0;
  let c_left = 0;
  let { width, height, } = getBox( elm );
  let { top, left, } = elm.getBoundingClientRect();
  if ( relative ) {
    const scroll = getScroll();
    scroll_x = scroll.x;
    scroll_y = scroll.y;
  }
  if ( ! isNil( context ) ) {
    const c_scroll = getElementScroll( context );
    const c = context.getBoundingClientRect();
    c_scroll_x = c_scroll.x;
    c_scroll_y = c_scroll.y;
    c_top = c.top;
    c_left = c.left;
  }
  else {
    c_top = -scroll_y;
    c_left = -scroll_x;
  }
  top = ( top + scroll_y ) - ( c_top + scroll_y ) + c_scroll_y;
  left = ( left + scroll_x ) - ( c_left + scroll_x ) + c_scroll_x;
  return {
    top,
    left,
    right: left + width,
    bottom: top + height,
    width,
    height,
  };
}
