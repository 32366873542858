import { onMounted, onUnmounted, } from 'vue';

export function useResizeListener( callback ) {
  window.addEventListener( 'resize', callback );
  window.addEventListener( 'orientationchange', callback );
}

function removeResizeListener ( callback ) {
  window.removeEventListener( 'resize', callback );
  window.removeEventListener( 'orientationchange', callback );
}

export function useMountedResizeListener ( callback ) {
  onMounted( () => useResizeListener( callback ) );
  onUnmounted( () => removeResizeListener( callback ) );
}

/**
 * @param { EventTarget } target
 * @param { Event } event
 * @param { Function } callback
 * @param { ?Object } option
 */
export function useEventListener( target, event, callback, option=null ) {
  if ( option ) target.addEventListener( event, callback, option );
  else target.addEventListener( event, callback, option );
}
