import 'whatwg-fetch'
import { ref, isRef, watchEffect, } from 'vue';

export function useFetch ( url, format='json' ) {
  const data = ref( null );
  const error = ref( null );
  const doFetch = async () => {
    try {
      const res = await fetch( url );
      if ( res.ok ) {
        const body = await res[ format ]();
        data.value = body;
      } else {
        throw new Error( `${ url } - ${ res.status }` );
      }
    } catch ( e ) {
      error.value = e;
    }
  };
  if ( isRef( url ) ) {
    watchEffect( doFetch );
  } else {
    doFetch();
  }
  return { data, error, };
}
